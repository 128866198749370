.App {
  text-align: center;
}

select {
  font-size: larger;
  min-width: 90%;
  margin:1vh;
  background-color: cadetblue;
}
label {
  margin-top:2vh;
}
textarea {
  font-size: larger;
  max-width: 100%;
  min-width: 90%;
  margin: 1vh;
  resize:none;
  background-color: cadetblue;
}
.formbraille {
  display:grid;
  
  text-align: center;
  
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .h1-anim {
    animation: h1-spin infinite 5s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.h1red {
  color: red;
}
td {
  text-align: left;
  border: 1px solid;
  padding: 2px;;
}
.divBrailleText {
  width:80%;
  overflow-wrap: break-word;
  text-align: left;
}
.brailletext {
  color:white;
  text-shadow: black 3px 3px;
  font-size: 2em;
}
input[type=submit]
{
  
  font-size: larger;
  width:50%;
  background-color: cadetblue;
  border: none;
  color: black;
  padding:1vh;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.8), 0 6px 20px 0 rgba(0,0,0,0.19);
  margin:0 auto;
}

.div50 {
  width:99%;
  
  overflow-wrap: break-word;
}
td.col1
{
  width:2%;
}
td.col2
{
  width:14%;
}
td.col3
{
  width:25%;
}
td.col4
{
  width:2%;
}
td.col5
{
  width:5%;
}
td.col6
{
  width:50%;
  word-break: break-all;
  background-color: rgb(46, 59, 70);
}
table {
  width: 80%;
  font-size: 0.8em;
  border: 1px solid;
  border-collapse: collapse;
  table-layout: auto;
}
.textgreen {
  color:greenyellow;
}
.textyellow {
  color:orange;
  text-shadow: black 3px 3px;
  font-size: 2em;
}

@keyframes h1-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(90deg);
  }
}
